<template>
  <a-drawer
    width="1000"
    placement="right"
    title="中标公告"
    :visible="visible"
    @close="onClose"
    :maskClosable="false">
    <!-- <div class="basic-box">
      <div class="basic-box-title">项目信息</div>
      <div class="basic-box-content">
        <div class="content-item flex-25">
          <div class="content-item-label">公告标题：</div>
          <div class="content-item-value">xxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">项目类型：</div>
          <div class="content-item-value">xxxxxxxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">发布人：</div>
          <div class="content-item-value">xxxxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">发布时间：</div>
          <div class="content-item-value">xxxxx</div>
        </div>
      </div>
    </div>
    <div class="basic-box">
      <div class="basic-box-title">项目合作公司信息</div>
      <div class="basic-box-content">
        <div class="content-item flex-25">
          <div class="content-item-label">合作企业：</div>
          <div class="content-item-value">xxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">统一社会信用代码：</div>
          <div class="content-item-value">xxxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">企业类型：</div>
          <div class="content-item-value">xxxxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">企业规模：</div>
          <div class="content-item-value">xxxxxxxxx</div>
        </div>
      </div>
    </div>
    <div class="basic-box">
      <div class="basic-box-title">联系人信息</div>
      <div class="basic-box-content">
        <div class="content-item flex-25">
          <div class="content-item-label">联系人：</div>
          <div class="content-item-value">xxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">性别：</div>
          <div class="content-item-value">xxxxxxxx</div>
        </div>
        <div class="content-item flex-25">
          <div class="content-item-label">联系方式：</div>
          <div class="content-item-value">xxxxxxxxx</div>
        </div>
      </div>
    </div> -->
    <div class="basic-box">
      <div class="basic-box-title">中标信息填写</div>
      <div class="basic-box-content">
        <a-form-model :model="form" ref="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <div class="basic-form">
            <a-form-model-item label="中标标题" :prop="'title'" :rules="{ required: true, message: '不能为空' }">
              <a-input v-model="form.title" placeholder="中标标题" :maxlength="50" />
            </a-form-model-item>
            <a-form-model-item label="中标展示信息填写" :prop="'info'" :rules="{ required: true, message: '不能为空' }">
              <vEditor v-model="form.info" />
            </a-form-model-item>
            <a-form-model-item label="资料附件" class="form-item">
              <VUpload v-model="form.fileUrl" :list-type="'text'" :accept="'.doc,.xlsx,.pdf'" :maxSize="10" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </div>
    <div class="btn-box">
      <a-button type="" @click="onClose">关闭</a-button>
      <a-button type="primary" :loading="loadingSave" @click="handleSubmit">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import vEditor from '@/components/common/v-editor/index'
import { typeList } from './dict'
import VUpload from '@/components/common/v-upload'
import { putAnnouncementResponseController } from '@/api/TenderingBidding'

export default {
  name: 'LookDetails',
  components: {
    vEditor,
    VUpload
  },
  data () {
    return {
      visible: false,
      typeList,
      form: {},
      loadingSave: false
    }
  },
  methods: {
    show (rowItem) {
      this.visible = true
      this.$set(this, 'form', {
        ...rowItem,
        info: '',
        title: '',
        fileUrl: []
      })
    },
    onClose () {
      this.$refs.form.resetFields()
      this.loadingSave = false
      this.visible = false
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const fileUrl = this.form.fileUrl
            .filter((file) => file.status === 'done')
            .map((item) => ({
              fileName: item.response.realName,
              filePath: item.response.path
            }))
          const model = {
            ...this.form,
            fileUrl: JSON.stringify(fileUrl),
            status: 1
          }
          this.loadingSave = true
          putAnnouncementResponseController(model)
            .then(() => {
              this.$emit('success')
              this.$message.success('中标公告已提交审核')
              this.onClose()
            })
            .finally(() => {
              this.loadingSave = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.basic-box {
  border: 1px solid #d9d9d9;
  color: #666666;
  margin-bottom: 24px;
  &-title {
    height: 60px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 60px;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 20px;
  }
  &-content {
    padding: 30px 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .content-item {
    display: flex;
    margin-bottom: 14px;
    &-value {
      flex: 1;
    }
  }
  .flex-100 {
    width: 100%;
  }
  .flex-75 {
    width: 75%;
  }
  .flex-50 {
    width: 50%;
  }
  .flex-25 {
    width: 25%;
  }
  .min-150 {
    min-width: 150px;
    white-space: pre-wrap;
    text-align: right;
  }
}
.btn-box {
  text-align: right;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  left: 0;
  border-radius: 0 0 4px 4px;
  width: 100%;
}
</style>
