
import { ACCESS_TOKEN } from '@/store/mutation-types'
import VueCookeis from 'vue-cookies'

export default {
    name: 'VUpload',
    inheritAttrs: false,
    props: {
        value: {
            type: [Array],
            default: () => []
        },
        accept: {
            type: String,
            default: '.png,.jpg' // 不要加空格
        },
        listType: {
            type: String,
            default: 'picture-card'
        },
        maxSize: {
            type: Number,
            default: 3
        },
        uploadSize: {
            type: Number,
            default: 100
        }
    },
    data () {
        return {
            action: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
            headers: {
                token: VueCookeis.get(ACCESS_TOKEN),
                clientId: process.env.VUE_APP_CLIENT_ID
            }
        }
    },
    methods: {
        validFile (file) {
            const { size, name } = file
            const typeList = this.accept.split(',')
            const dotIdx = name.lastIndexOf('.') // 获取最后一个.的位置
            const suffix = name.substr(dotIdx) // 获取文件后缀
            if (!typeList.includes(suffix.toLowerCase())) {
                this.$message.warning('文件格式不正确')
                return false
            } else if (size > this.uploadSize * 1024 * 1024) {
                this.$message.warning(`文件大小不要超过${this.uploadSize}MB`)
                return false
            } else {
                return true
            }
        },
        handleChange ({ file, fileList, event }) {
            if (this.validFile(file)) {
                console.log(fileList.slice(0, this.maxSize))
                this.$emit('input', fileList.slice(0, this.maxSize))
            }
        },
        handlePreview (file) {

        },
        renderDefault () {
            if (this.listType === 'text') {
                return (
                    <a-button type='primary' > <a-icon type="upload" />上传附件（{this.accept}） </a-button>
                )
            }
            if (this.listType === 'picture-card') {
                return (this.value || []).length < this.maxSize && (
                    <div class='btn-upload' style={{
                        width: '86px',
                        height: '86px',
                        background: '#fafafa',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        cursor: 'pointer'
                    }} >
                        <a-icon type="plus" />
                                <div class="ant-upload-text">
                                    上传
                                </div>
                    </div>
                )
            }
        }
    },
    render () {
        return (
            <a-upload
                {
                ...{
                    attrs: {
                        class: 'v-upload'
                    },
                    props: {
                        name: 'file',
                        action: this.action,
                        headers: this.headers,
                        multiple: true,
                        fileList: this.value,
                        accept: this.accept,
                        listType: this.listType
                    },
                    on: {
                        change: this.handleChange,
                        preview: this.handlePreview
                    }
                }
                }
            >
                {this.$slots.default || this.renderDefault()}
            </a-upload>
        )
    }
}
