import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import VueCookeis from 'vue-cookies'

export default {
    name: 'VEdiotr',
    components: { Editor, Toolbar },
    props: {
        value: {
            type: [String, undefined, null]
        }
    },
    data () {
        return {
            headers: {
                token: VueCookeis.get(ACCESS_TOKEN),
                clientId: process.env.VUE_APP_CLIENT_ID
            },
            action: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
            editor: null,
            toolbarConfig: {
                excludeKeys: [
                    // 行内代码
                    'code',
                    // 图片
                    'divider',
                    'emotion',
                    'insertLink',
                    'editLink',
                    'unLink',
                    'viewLink',
                    'codeBlock',
                    'blockquote',
                    'headerSelect',
                    'header1',
                    'header2',
                    'header3',
                    'header4',
                    'header5',
                    'todo',
                    'redo',
                    'undo',
                    'fullScreen',
                    'enter',
                    'bulletedList',
                    'numberedList',
                    'insertTable',
                    'deleteTable',
                    'insertTableRow',
                    'deleteTableRow',
                    'insertTableCol',
                    'deleteTableCol',
                    'tableHeader',
                    'tableFullWidth',
                    'insertVideo',
                    'uploadVideo',
                    'editVideoSize'
                ]
            },
            editorConfig: { placeholder: '请输入内容...' }
        }
    },
    methods: {
        onCreated (editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            // 上传地址
            editor.getConfig().MENU_CONF['uploadImage'] = {
                server: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
                // form-data fieldName ，默认值 'wangeditor-uploaded-image'
                fieldName: 'file',

                // 单个文件的最大体积限制，默认为 2M
                maxFileSize: 1 * 1024 * 1024, // 1M

                // 最多可上传几个文件，默认为 100
                maxNumberOfFiles: 10,

                // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
                allowedFileTypes: ['image/*'],

                // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
                meta: {
                    // token: VueCookeis.get(ACCESS_TOKEN)
                    // otherKey: 'yyy'
                },

                // 将 meta 拼接到 url 参数中，默认 false
                metaWithUrl: false,

                // 自定义增加 http  header
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    token: VueCookeis.get(ACCESS_TOKEN)
                },

                // 跨域是否传递 cookie ，默认为 false
                withCredentials: false,

                // 超时时间，默认为 10 秒
                timeout: 5 * 1000, // 5 秒
                customInsert (res, insertFn) {
                    // JS 语法
                    // res 即服务端的返回结果
                    // 从 res 中找到 url alt href ，然后插图图片
                    insertFn(res.path, res.fileName, res.path)
                }
            }
            // console.log('工具栏配置', editor.getConfig())
            // console.log('工具栏配置', editor.getAllMenuKeys())
        },
        handleInput (value) {
            this.$emit('input', value)
        }
    },
    render () {
        return (
            <div style="border: 1px solid #ccc">
                <Toolbar
                    style="border-bottom: 1px solid #ccc"
                    editor={this.editor}
                    defaultConfig={this.toolbarConfig}
                    mode={this.mode}
                />
                <Editor
                    style="height: 300px; overflow-y: hidden"
                    defaultConfig={this.editorConfig}
                    value={this.value}
                    mode={this.mode}
                    {
                    ...{
                        on: {
                            onCreated: this.onCreated,
                            input: this.handleInput
                        }
                    }
                    }
                />
            </div>
        )
    }
}
