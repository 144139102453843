import request from '@/utils/request'

// 分页查询
export function queryPageByConditionGET (parameter) {
  return request({
    url: '/api/employ/AnnouncementController/queryPageByCondition',
    method: 'get',
    params: parameter
  })
}
// 新增公告
export function AnnouncementControllerPost (parameter) {
  return request({
    url: '/api/employ/AnnouncementController',
    method: 'post',
    data: parameter
  })
}

// 响应公告
export function AnnouncementResponseController (parameter) {
  return request({
    url: '/api/a/AnnouncementResponseController',
    method: 'post',
    data: parameter
  })
}
// 修改
export function AnnouncementControllerPut (parameter) {
  return request({
    url: '/api/employ/AnnouncementController',
    method: 'put',
    data: parameter
  })
}
// 删除
export function AnnouncementControllerDel (parameter) {
  return request({
    url: '/api/employ/AnnouncementController',
    method: 'DELETE',
    params: parameter,
    paramsSerializer: {
      serialize: function (parameter) {
        const myParam = parameter.ids.map((_) => `ids=${_}`).join('&')
        return myParam
      }
    }
  })
}
// 批量修改
export function updateBatch (parameter) {
  return request({
    url: '/api/employ/AnnouncementController/updateBatch',
    method: 'put',
    data: parameter
  })
}
// 上传附件
export function uploadFile (parameter) {
  return request({
    url: `/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
    method: 'POST',
    data: parameter
  })
}

// 分页查询
export function getDetailsById (id) {
  return request({
    url: '/api/employ/AnnouncementController/queryOneByCondition',
    method: 'get',
    params: {
      id
    }
  })
}

// 查询公告响应数据不分页
export function queryListByCondition (id, enterpriseName) {
  return request({
    url: '/api/a/AnnouncementResponseController/queryListByCondition',
    method: 'get',
    params: {
      anId: id,
      enterpriseName
    }
  })
}

// 分页查询公告响应数据
export function queryPageByCondition (params) {
  return request({
    url: '/api/a/AnnouncementResponseController/queryPageByCondition',
    method: 'get',
    params
  })
}

// 查询一条公告响应数据
export function queryOneByCondition (params) {
  return request({
    url: '/api/a/AnnouncementResponseController/queryOneByCondition',
    method: 'get',
    params
  })
}

// 更新公告响应数据
export function putAnnouncementResponseController (data) {
  return request({
    url: '/api/a/AnnouncementResponseController',
    method: 'put',
    data
  })
}
